import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress, Grid, TextField, Typography, Box } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import axios from "axios";
import usePlans from "../../hooks/usePlans";
import { AuthContext } from "../../context/Auth/AuthContext";
import IntegrationExamples from "./IntegrationExamples"; // Importação de exemplos de integração
import { CloudUpload, FileCopy } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(5),
    backgroundColor: theme.palette.type === 'dark' ? "#303030" : "#f1f5f9",
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  },
  formContainer: {
    maxWidth: 700,
    margin: 'auto',
    padding: theme.spacing(4),
    borderRadius: '12px',
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    color: theme.palette.type === 'dark' ? "#90caf9" : "#1a73e8",
    fontWeight: 700,
    fontSize: '1.5rem',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    fontFamily: "'Poppins', sans-serif",
  },
  sectionTitle: {
    color: theme.palette.type === 'dark' ? "#90caf9" : "#1a237e",
    fontWeight: 600,
    fontSize: '1.25rem',
    marginBottom: theme.spacing(2),
    fontFamily: "'Poppins', sans-serif",
  },
  codeBox: {
    backgroundColor: theme.palette.type === 'dark' ? "#424242" : "#212121",
    padding: theme.spacing(3),
    color: "#ffffff",
    borderRadius: "10px",
    marginTop: theme.spacing(3),
    fontFamily: "'Roboto Mono', monospace",
  },
  instructionBox: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.type === 'dark' ? "#37474f" : "#e3f2fd",
    borderRadius: "12px",
    marginBottom: theme.spacing(4),
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.type === 'dark' ? "#455a64" : "#bbdefb",
    },
    cursor: 'pointer',
  },
  copyIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
  fileInput: {
    display: 'none',
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    padding: theme.spacing(1.5, 3),
    borderRadius: '6px',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 500,
  },
  fileName: {
    marginTop: theme.spacing(1),
    color: theme.palette.type === 'dark' ? "#90caf9" : "#1a1a1a",
    fontSize: "0.875rem",
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  textRight: {
    textAlign: 'right',
  },
}));

const MessagesAPI = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext); // Pega o usuário autenticado
  const { getPlanCompany } = usePlans();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  // Verificação de permissões
  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(undefined, companyId);

      // Verificação de permissão da empresa
      if (!planConfigs.plan.useExternalApi) {
        toast.error("Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando.");
        setTimeout(() => {
          history.push(`/`);
        }, 1000);
        return;
      }
    }
    fetchData();
  }, [history, user.companyId, getPlanCompany]);
  const getEndpoint = () => {
    return process.env.REACT_APP_BACKEND_URL + '/api/messages/send';
  };

  // Função para copiar para área de transferência
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Texto copiado para a área de transferência!");
    });
  };

  // Explicação da API de envio de texto
  const renderApiExplanation = () => (
    <>
      <Typography variant="h6" className={classes.sectionTitle}>1. Envio de Mensagens de Texto</Typography>
      <Box className={classes.instructionBox} onClick={() => copyToClipboard(`Endpoint: ${getEndpoint()} \nAuthorization: Bearer {token} \nContent-Type: application/json`)}>
        <Typography variant="h6">Informações necessárias para envio de mensagens de texto:</Typography>
        <Box className={classes.codeBox}>
          <Typography>
            <b>Endpoint:</b> {getEndpoint()} <br />
            <b>Método:</b> POST <br />
            <b>Headers:</b> Authorization Bearer (token) e Content-Type (application/json) <br />
            <b>Body:</b> <br />
            {"{"}
            <br />
            "number": "558599999999",<br />
            "body": "Message",<br />
            "userId": "ID do Atendente ou ''",<br />
            "queueId": "ID da Fila ou ''",<br />
            "sendSignature": "Assinar mensagem - true/false",<br />
            "status": "Encerrar o ticket - open/closed"<br />
            {"}"}
          </Typography>
          <FileCopy className={classes.copyIcon} />
        </Box>
      </Box>

      <Typography variant="h6" className={classes.sectionTitle}>2. Envio de Mensagens de Mídia</Typography>
      <Box className={classes.instructionBox} onClick={() => copyToClipboard(`Endpoint: ${getEndpoint()} \nAuthorization: Bearer {token} \nContent-Type: multipart/form-data`)}>
        <Typography variant="h6">Informações necessárias para envio de mensagens de mídia:</Typography>
        <Box className={classes.codeBox}>
          <Typography>
            <b>Endpoint:</b> {getEndpoint()} <br />
            <b>Método:</b> POST <br />
            <b>Headers:</b> Authorization Bearer (token) e Content-Type (multipart/form-data) <br />
            <b>FormData:</b> <br />
            "number": "558599999999",<br />
            "body": "Message",<br />
            "userId": "ID do Atendente ou ''",<br />
            "queueId": "ID da Fila ou ''",<br />
            "medias": "Arquivo de mídia",<br />
            "sendSignature": "Assinar mensagem - true/false",<br />
            "status": "Encerrar ticket - open/closed"<br />
          </Typography>
          <FileCopy className={classes.copyIcon} />
        </Box>
      </Box>
    </>
  );

  const validationSchema = Yup.object().shape({
    token: Yup.string().required('Token é obrigatório'),
    number: Yup.string()
      .matches(/^[0-9]+$/, 'O número deve conter apenas dígitos')
      .required('Número é obrigatório'),
    body: Yup.string()
      .required('Mensagem é obrigatória')
      .test('not-only-spaces', 'A mensagem não pode conter apenas espaços', (value) => value && value.trim().length > 0),
    userId: Yup.string()
      .matches(/^[0-9]*$/, 'O ID de usuário deve conter apenas dígitos ou estar vazio')
      .nullable(),
    queueId: Yup.string()
      .matches(/^[0-9]*$/, 'O ID da fila deve conter apenas dígitos ou estar vazio')
      .nullable(),
  });

  const handleSendTextMessage = async (values) => {
    const { number, body, userId, queueId } = values;
    const data = { number, body, userId, queueId };
    try {
      await axios.request({
        url: getEndpoint(),
        method: 'POST',
        data,
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${values.token}`
        }
      });
      toast.success('Mensagem enviada com sucesso');
    } catch (err) {
      toastError(err);
    }
  };

  const handleSendMediaMessage = async (values) => {
    if (!file) {
      toast.error("Selecione um arquivo para enviar.");
      return;
    }

    try {
      const data = new FormData();
      data.append('number', values.number);
      data.append('body', values.body ? values.body : file.name);
      data.append('userId', values.userId);
      data.append('queueId', values.queueId);
      data.append('medias', file);
      await axios.request({
        url: getEndpoint(),
        method: 'POST',
        data,
        headers: {
          'Content-type': 'multipart/form-data',
          'Authorization': `Bearer ${values.token}`
        }
      });
      toast.success('Mensagem enviada com sucesso');
      setFile(null);
      setFileName("");
      document.getElementById('medias').value = null;
    } catch (err) {
      toastError(err);
    }
  };

  const renderFormMessageText = () => (
    <Formik
      initialValues={{ token: '', number: '', body: '', userId: '', queueId: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setTimeout(async () => {
          await handleSendTextMessage(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }, 400);
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className={classes.formContainer}>
          <Typography variant="h6" className={classes.sectionTitle}>Teste de Envio de Texto</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Field
                as={TextField}
                label="Token"
                name="token"
                variant="outlined"
                margin="dense"
                fullWidth
                required
                error={touched.token && Boolean(errors.token)}
                helperText={touched.token && errors.token}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                as={TextField}
                label="Número"
                name="number"
                variant="outlined"
                margin="dense"
                fullWidth
                required
                inputProps={{ maxLength: 15, inputMode: 'numeric', pattern: '[0-9]*' }}
                error={touched.number && Boolean(errors.number)}
                helperText={touched.number && errors.number}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Mensagem"
                name="body"
                variant="outlined"
                margin="dense"
                fullWidth
                required
                error={touched.body && Boolean(errors.body)}
                helperText={touched.body && errors.body}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                as={TextField}
                label="ID do Atendente (Opcional)"
                name="userId"
                variant="outlined"
                margin="dense"
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                as={TextField}
                label="ID da Fila (Opcional)"
                name="queueId"
                variant="outlined"
                margin="dense"
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </Grid>
            <Grid item xs={12} className={classes.textRight}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.buttonWrapper}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'ENVIAR'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  const renderFormMessageMedia = () => (
    <Formik
      initialValues={{ token: '', number: '', body: '', userId: '', queueId: '' }}
      onSubmit={(values, actions) => {
        if (!file) {
          actions.setFieldError("medias", "O arquivo é obrigatório");
          return;
        }
        setTimeout(async () => {
          await handleSendMediaMessage(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }, 400);
      }}
    >
      {({ isSubmitting, setFieldValue, errors, touched }) => (
        <Form className={classes.formContainer}>
          <Typography variant="h6" className={classes.sectionTitle}>Teste de Envio de Mídia</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Field
                as={TextField}
                label="Token"
                name="token"
                variant="outlined"
                margin="dense"
                fullWidth
                required
                error={touched.token && Boolean(errors.token)}
                helperText={touched.token && errors.token}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                as={TextField}
                label="Número"
                name="number"
                variant="outlined"
                margin="dense"
                fullWidth
                required
                inputProps={{ maxLength: 15, inputMode: 'numeric', pattern: '[0-9]*' }}
                error={touched.number && Boolean(errors.number)}
                helperText={touched.number && errors.number}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Mensagem (Opcional)"
                name="body"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                as={TextField}
                label="ID do Atendente (Opcional)"
                name="userId"
                variant="outlined"
                margin="dense"
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                as={TextField}
                label="ID da Fila (Opcional)"
                name="queueId"
                variant="outlined"
                margin="dense"
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                id="medias"
                name="medias"
                className={classes.fileInput}
                onChange={(event) => {
                  setFieldValue("medias", event.currentTarget.files[0]);
                  setFile(event.currentTarget.files[0]);
                  setFileName(event.currentTarget.files[0]?.name || "");
                }}
                required
              />
              <label htmlFor="medias" className={classes.uploadButton}>
                <CloudUpload style={{ marginRight: 8 }} /> Selecionar Arquivo
              </label>
              {fileName && <Typography className={classes.fileName}>{`Arquivo Selecionado: ${fileName}`}</Typography>}
              {errors.medias && touched.medias && (
                <Typography color="error">O arquivo é obrigatório</Typography>
              )}
            </Grid>
            <Grid item xs={12} className={classes.textRight}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.buttonWrapper}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'ENVIAR'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <Paper className={classes.mainPaper}>
      <Typography variant="h5" className={classes.header}>Documentação para Envio de Mensagens</Typography>

      {/* Explicações da API */}
      {renderApiExplanation()}

      {/* Formulários de envio de mensagens */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {renderFormMessageText()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderFormMessageMedia()}
        </Grid>
      </Grid>

      {/* Observações Importantes */}
      <Typography variant="h6" className={classes.sectionTitle}>Observações Importantes</Typography>
      <Box className={classes.instructionBox}>
        <Typography>
          Antes de enviar mensagens, é necessário cadastrar o token vinculado à conexão que enviará as mensagens. Para realizar o cadastro, acesse o menu 'Conexões', clique no botão editar da conexão e insira o token no campo correspondente.
        </Typography>
      </Box>

      {/* Exemplo de integração com várias linguagens */}
      <Typography variant="h6" className={classes.sectionTitle}>Exemplo de Integração</Typography>
      <IntegrationExamples /> {/* Componente de exemplos */}
    </Paper>
  );
};

export default MessagesAPI;
