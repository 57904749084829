import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';

const MyComponent = ({ iframeSrc }) => {
  const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/interno.png`; // Caminho da logo

  return (
    <div style={{ position: 'relative', height: '800px' }}> {/* Define altura para o container */}
      {!iframeSrc ? (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100%' // Centraliza verticalmente e horizontalmente 
        }}>
          <img
            style={{ width: '200px' }}  // Largura da logo
            src={logo}
            alt="Logo"
          />
        </div>
      ) : (
        <iframe
          title="TypeBot Editor"
          src={iframeSrc}
          style={{ width: '100%', height: '800px', border: 'none' }}
        />
      )}
      <style>
        {`
          /* Esconda o botão de voltar */
          .MuiButtonBase-root.MuiFab-root.MuiFab-circular.MuiFab-sizeSmall.MuiFab-primary.css-1rhbf4w {
            display: none;
          }

          /* Desative o botão de chat */
          .MuiFab-root {
            pointer-events: none;
            opacity: 0.5;
          }
        `}
      </style>
    </div>
  );
};

const App = () => {
  const [iframeSrc, setIframeSrc] = useState(''); // Inicializa vazio para exibir logo por padrão

  useEffect(() => {
    // Carrega o iframeSrc do localStorage para o Typebot, se existir
    const storedIframeSrc = localStorage.getItem('iframeSrcTypebot');
    if (storedIframeSrc) {
      setIframeSrc(storedIframeSrc);
    }
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSrcChange = (event) => {
    setIframeSrc(event.target.value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    // Armazena o iframeSrc no localStorage específico para Typebot
    localStorage.setItem('iframeSrcTypebot', iframeSrc);
    setIsModalOpen(false);
  };

  return (
    <div>
      <center>
        <Button variant="contained" color="primary" onClick={handleOpenModal}>
          INSERIR URL DO Typebot
        </Button>
      </center>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="modal-modal-title">Configurações</h2>
          <TextField
            fullWidth
            id="iframeSrcInput"
            label="URL CANVAS TypeBot"
            variant="outlined"
            value={iframeSrc}
            onChange={handleSrcChange}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleCloseModal} style={{ marginTop: '20px' }}>
            Salvar
          </Button>
        </Box>
      </Modal>
      <MyComponent iframeSrc={iframeSrc} />
    </div>
  );
};

export default App;
