import { IconButton, Slider } from "@material-ui/core";
import React, { useRef, useEffect, useState } from "react";
import { PlayArrow, Pause, Speed, GetApp, VolumeUp } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const LS_NAME = 'audioMessageRate';

const useStyles = makeStyles((theme) => ({
  audioPlayerContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.type === 'light' ? "#f0f0f0" : "#2c2f33", 
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
    width: "100%",
    justifyContent: "space-between",
    position: "relative",
  },
  progressBar: {
    width: "100%",
    height: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.type === 'light' ? "#e0e0e0" : "#ffffff50", 
    margin: "0 10px",
    position: "relative",
    cursor: "pointer",
  },
  progress: {
    height: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main, 
    width: "0%", 
    position: "absolute",
    top: "0",
    left: "0",
  },
  timeInfo: {
    color: theme.palette.text.primary, 
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    marginBottom: "-5px", 
  },
  playButton: {
    color: theme.palette.primary.main, 
  },
  rateButton: {
    color: theme.palette.primary.main, 
    display: "flex",
    alignItems: "center",
  },
  downloadContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  downloadButton: {
    marginLeft: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    padding: "5px 10px",
    borderRadius: "5px",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "12px",
    transition: "0.3s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  icon: {
    fontSize: "1.5rem",
  },
  downloadIconSmall: {
    fontSize: "1.2rem",
    color: theme.palette.primary.main, 
    cursor: "pointer",
    transition: "0.3s ease-in-out",
  },
  volumeControl: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "10px",
    position: "relative",
  },
  volumeIcon: {
    color: theme.palette.primary.main, 
    marginRight: "5px",
  },
  volumeSliderContainer: {
    position: "absolute",
    bottom: "40px",  // Ajuste a posição vertical conforme necessário
    left: "50%",      // Centraliza horizontalmente
    transform: "translateX(-50%)", // Compensa a largura para centralizar
    display: "none",
    width: "100px",
    zIndex: 10,
    backgroundColor: theme.palette.type === 'light' ? "#ffffff" : "#424242",
    borderRadius: "5px",
    padding: "5px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
  },
  volumeSlider: {
    width: "100%",
    color: theme.palette.text.primary, 
  },
  showVolumeSlider: {
    display: "block", 
  }
}));

let activeAudio = null;

const AudioModal = ({ url }) => {
  const theme = useTheme();
  const classes = useStyles();
  const audioRef = useRef(null);
  const [audioRate, setAudioRate] = useState(parseFloat(localStorage.getItem(LS_NAME) || "1"));
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");
  const [hasPlayed, setHasPlayed] = useState(false); // Novo estado para controlar a reprodução
  const [showRate, setShowRate] = useState(false); 
  const [showDownloadText, setShowDownloadText] = useState(false); 
  const [volume, setVolume] = useState(100); 
  const [showVolume, setShowVolume] = useState(false); 
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  useEffect(() => {
    audioRef.current.playbackRate = audioRate;
    localStorage.setItem(LS_NAME, audioRate);
  }, [audioRate]);

  useEffect(() => {
    const audio = audioRef.current;
    const updateProgress = () => {
      const percent = (audio.currentTime / audio.duration) * 100;
      setProgress(percent);
      setCurrentTime(formatTime(audio.currentTime));
    };

    const updateDuration = () => {
      if (audio.duration && !hasPlayed) { // Define a duração apenas se ainda não foi reproduzido
        setDuration(formatTime(audio.duration));
      }
    };

    const onEnded = () => {
      setIsPlaying(false);
      setHasPlayed(false); // Resetar o estado de reprodução
      activeAudio = null;
    };

    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("loadedmetadata", updateDuration);
    audio.addEventListener("ended", onEnded);

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("loadedmetadata", updateDuration);
      audio.removeEventListener("ended", onEnded);
    };
  }, [hasPlayed]);

  const togglePlay = () => {
    if (activeAudio && activeAudio !== audioRef.current) {
      activeAudio.pause(); 
      setIsPlaying(false);
    }
    
    if (isPlaying) {
      audioRef.current.pause();
      setHasPlayed(false); // Indica que a reprodução foi pausada
    } else {
      audioRef.current.play();
      activeAudio = audioRef.current; 
      setHasPlayed(true); // Indica que a reprodução começou
    }
    setIsPlaying(!isPlaying);
  };

  const handleTouchStart = () => {
    if (activeAudio && activeAudio !== audioRef.current) {
      activeAudio.pause(); 
      setIsPlaying(false); 
    }
    if (!isPlaying) {
      audioRef.current.play();
      activeAudio = audioRef.current; 
      setHasPlayed(true); // Indica que a reprodução começou
    }
    setIsPlaying(true);
  };

  const toggleRate = () => {
    let newRate = null;
    switch (audioRate) {
      case 0.5:
        newRate = 1;
        break;
      case 1:
        newRate = 1.5;
        break;
      case 1.5:
        newRate = 2;
        break;
      case 2:
        newRate = 0.5;
        break;
      default:
        newRate = 1;
        break;
    }
    setAudioRate(newRate);
    setShowRate(true); 

    setTimeout(() => {
      setShowRate(false);
    }, 2000);
  };

  const getAudioSource = () => {
    let sourceUrl = url;
    if (isIOS) {
      sourceUrl = sourceUrl.replace(".ogg", ".mp3");
    }
    return <source src={sourceUrl} type={isIOS ? "audio/mp3" : "audio/ogg"} />;
  };

  const formatTime = (time) => {
    if (isNaN(time)) return "00:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleProgressClick = (e) => {
    const width = e.target.clientWidth;
    const clickX = e.nativeEvent.offsetX;
    const duration = audioRef.current.duration;
    audioRef.current.currentTime = (clickX / width) * duration;
  };

  const handleVolumeChange = (e, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue / 100;
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split('/').pop(); 
      const objectURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = objectURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
      setShowDownloadText(false);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };

  const toggleDownloadText = () => {
    setShowDownloadText(!showDownloadText);
  };

  const toggleVolume = () => {
    setShowVolume(true);
    // Timeout para esconder o controle de volume após 2 segundos
    setTimeout(() => setShowVolume(false), 2000);
  };

  return (
    <div className={classes.audioPlayerContainer}>
      <audio ref={audioRef}>
        {getAudioSource()}
      </audio>
      <IconButton className={classes.playButton} onClick={togglePlay} onTouchStart={handleTouchStart}>
        {isPlaying ? (
          <Pause className={classes.icon} />
        ) : (
          <PlayArrow className={classes.icon} />
        )}
      </IconButton>
      <div className={classes.progressBar} onClick={handleProgressClick}>
        <div className={classes.progress} style={{ width: `${progress}%` }} />
      </div>
      <div className={classes.timeInfo}>
        <IconButton className={classes.rateButton} onClick={toggleRate}>
          <Speed className={classes.icon} />
        </IconButton>
        {showRate && <span style={{ marginLeft: "5px", color: theme.palette.primary.main }}>{audioRate}x</span>}
        {/* Exibir apenas a duração inicialmente, e o tempo atual durante a reprodução */}
        {hasPlayed ? (
          <span style={{ color: theme.palette.text.primary }}>{currentTime} / {duration}</span>
        ) : (
          <span style={{ color: theme.palette.text.primary }}>{duration}</span>
        )}
      </div>

      <div className={classes.volumeControl}>
        <IconButton onClick={toggleVolume}>
          <VolumeUp className={classes.volumeIcon} />
        </IconButton>
        <div className={`${classes.volumeSliderContainer} ${showVolume ? classes.showVolumeSlider : ""}`}>
          <Slider
            value={volume}
            onChange={handleVolumeChange}
            aria-labelledby="continuous-slider"
            className={classes.volumeSlider}
          />
        </div>
      </div>

      <div className={classes.downloadContainer}>
        <IconButton className={classes.downloadIconSmall} onClick={toggleDownloadText}>
          <GetApp className={classes.downloadIconSmall} />
        </IconButton>
        {showDownloadText && (
          <div className={classes.downloadButton} onClick={handleDownload}>
            <GetApp style={{ marginRight: "5px", fontSize: "1rem" }} />
            Download
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioModal;
